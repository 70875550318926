<template>
  <div v-if="screen_width > 450" style="position: relative; width: 100%">
    <div style="position: relative; width: 100%; display: flex; align-content: space-between">
      <div style="flex-grow: 1">
        <svg
            style="width: max(80px, 7vw); height: max(80px, 5vw); margin-right: 1.5rem; cursor: pointer"
            viewBox="0 0 115 87" fill="none" xmlns="http://www.w3.org/2000/svg"
            @click="redirectMain"
        >
          <path d="M8.19694 61.04C5.52155 61.04 3.47232 60.37 2.04924 59.0301C0.683084 57.6344 0 55.6524 0 53.0843V9.95575C0 7.38758 0.683084 5.43354 2.04924 4.09362C3.47232 2.69788 5.52155 2 8.19694 2H37.1424C39.1916 2 40.7285 2.50247 41.7532 3.50741C42.7778 4.51235 43.2901 5.96392 43.2901 7.86213C43.2901 9.81617 42.7778 11.3236 41.7532 12.3843C40.7285 13.3893 39.1916 13.8917 37.1424 13.8917H15.0277V25.1135H35.2639C37.2562 25.1135 38.7647 25.616 39.7893 26.6209C40.8708 27.6259 41.4116 29.1054 41.4116 31.0594C41.4116 33.0134 40.8708 34.4929 39.7893 35.4979C38.7647 36.5028 37.2562 37.0053 35.2639 37.0053H15.0277V49.1483H37.1424C39.1916 49.1483 40.7285 49.6786 41.7532 50.7394C42.7778 51.7443 43.2901 53.1959 43.2901 55.0941C43.2901 57.0482 42.7778 58.5277 41.7532 59.5326C40.7285 60.5375 39.1916 61.04 37.1424 61.04H8.19694Z" fill="#9829EF"/>
          <path d="M8.98474 7.73999H107" stroke="#9829EF" stroke-width="15" stroke-linecap="round"/>
          <path d="M8.98474 30.7H107" stroke="#9829EF" stroke-width="15" stroke-linecap="round"/>
          <path d="M8.98474 54.48H107" stroke="#9829EF" stroke-width="15" stroke-linecap="round"/>
          <path d="M28.7969 83.9999C20.3651 77.2671 22.4455 65.3554 25.7886 59.3999H35.8173C29.5993 65.8217 33.4027 77.2671 37.5725 81.6693C32.1491 81.1516 28.7969 77.2671 28.7969 77.2671C28.396 77.4745 27.8871 81.4105 28.7969 83.9999Z" fill="#9829EF" stroke="#9829EF" stroke-width="2"/>
        </svg>
      </div>
      <div style="flex-grow: 1; display: flex; align-content: space-between">
        <div style="flex-grow: 1; margin-right: 0.5rem">
          <div style="display: table; height: 80%">
            <a href="/features" @click.prevent="$router.push('/features')" class="ytm-landing-font ytm-landing-head-top-text" style="display: table-cell; vertical-align: middle">
              Возможности
            </a>
          </div>
        </div>
        <div style="flex-grow: 1; margin-right: 0.5rem">
          <div style="display: table; height: 80%">
            <a href="/tariffs" @click.prevent="$router.push('/tariffs')" class="ytm-landing-font ytm-landing-head-top-text" style="display: table-cell; vertical-align: middle">
              Тарифы
            </a>
          </div>
        </div>
        <div style="flex-grow: 1; margin-right: 0.5rem">
          <div style="display: table; height: 80%">
            <a href="#" @click.prevent="$emit('scrollToFooter')" class="ytm-landing-font ytm-landing-head-top-text" style="display: table-cell; vertical-align: middle">
              Контакты
            </a>
          </div>
        </div>
        <div>
          <div style="display: table; height: 80%">
            <div style="display: table-cell; vertical-align: middle">
              <button v-if="!is_auth" class="ytm-landing-head-top-button-login" @click="$router.push('/login')">
                  <span class="ytm-landing-font ytm-landing-head-top-button-login-text">
                    Войти
                  </span>
              </button>
              <button v-else class="ytm-landing-head-top-button-login" @click="logout">
                  <span class="ytm-landing-font ytm-landing-head-top-button-login-text">
                    Выйти
                  </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else style="position: relative; height: 46px">
    <svg class="ytm-mobile-star" viewBox="0 0 162 117" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M-25.4292 105.726C0.300531 123.997 35.8798 118.598 55.0315 93.517L59.2119 88.0423C70.0263 73.8798 87.8772 66.9894 105.403 70.213C136.22 75.8814 163.943 50.5866 161.115 19.3805L159.667 3.39844C158.484 -42.7181 126.162 -82.1631 81.1814 -92.4046L67.0408 -96.6019C34.0521 -106.394 -0.316376 -86.1413 -7.73378 -52.539C-10.8711 -38.3265 -19.1261 -25.7642 -30.9267 -17.2446L-34.3032 -14.807C-60.3802 4.01957 -70.3339 38.1554 -58.4627 68.0473L-58.1638 68.8C-53.2247 81.2367 -44.7906 91.9774 -33.88 99.725L-25.4292 105.726Z" fill="url(#paint0_linear_122_138)"/>
      <defs>
        <linearGradient id="paint0_linear_122_138" x1="17.4696" y1="109.091" x2="81.824" y2="-94.8575" gradientUnits="userSpaceOnUse">
          <stop stop-color="#9747FF"/>
          <stop offset="1" stop-color="#D55EFF"/>
        </linearGradient>
      </defs>
    </svg>
    <svg class="ytm-mobile-star-logo" @click="redirectMain" viewBox="0 0 50 38" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.52392 26.3817C2.37375 26.3817 1.49277 26.0937 0.880982 25.5176C0.293662 24.9176 0 24.0655 0 22.9615V4.42023C0 3.31616 0.293662 2.4761 0.880982 1.90006C1.49277 1.30002 2.37375 1 3.52392 1H15.9678C16.8487 1 17.5095 1.21602 17.95 1.64805C18.3904 2.08007 18.6107 2.70411 18.6107 3.52017C18.6107 4.36022 18.3904 5.00827 17.95 5.4643C17.5095 5.89633 16.8487 6.11234 15.9678 6.11234H6.46052V10.9367H15.1602C16.0167 10.9367 16.6652 11.1527 17.1057 11.5847C17.5706 12.0167 17.8031 12.6528 17.8031 13.4928C17.8031 14.3329 17.5706 14.9689 17.1057 15.401C16.6652 15.833 16.0167 16.049 15.1602 16.049H6.46052V21.2693H15.9678C16.8487 21.2693 17.5095 21.4974 17.95 21.9534C18.3904 22.3854 18.6107 23.0095 18.6107 23.8255C18.6107 24.6656 18.3904 25.3016 17.95 25.7336C17.5095 26.1657 16.8487 26.3817 15.9678 26.3817H3.52392Z" fill="white"/>
      <path d="M3.86279 3.46777H46.0002" stroke="white" stroke-width="6.4486" stroke-linecap="round"/>
      <path d="M3.86279 13.3384H46.0002" stroke="white" stroke-width="6.4486" stroke-linecap="round"/>
      <path d="M3.86279 23.5615H46.0002" stroke="white" stroke-width="6.4486" stroke-linecap="round"/>
      <path d="M12.3799 36.2522C8.75506 33.3577 9.64943 28.2368 11.0866 25.6765H15.3981C12.7249 28.4373 14.36 33.3577 16.1526 35.2503C13.8211 35.0277 12.3799 33.3577 12.3799 33.3577C12.2076 33.4469 11.9888 35.139 12.3799 36.2522Z" fill="white" stroke="white" stroke-width="0.859813"/>
    </svg>
    <div class="ytm-burger-menu-button" @click="$router.push('/login')">
      <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <rect width="46" height="46" rx="7" fill="white" fill-opacity="0.62"/>
        <rect x="0.5" y="0.5" width="45" height="45" rx="6.5" stroke="white" stroke-opacity="0.3"/>
        <mask id="mask0_122_275" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="8.5" y="8.5" width="29" height="29">
          <rect x="8.5" y="8.5" width="29" height="29" fill="url(#pattern0)"/>
        </mask>
        <g mask="url(#mask0_122_275)">
          <rect x="-2" y="-2" width="56" height="54" fill="#9747FF"/>
        </g>
        <defs>
          <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlink:href="#image0_122_275" transform="scale(0.0111111)"/>
          </pattern>
          <image id="image0_122_275" width="90" height="90" xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFoAAABaCAYAAAA4qEECAAAABmJLR0QA/wD/AP+gvaeTAAACO0lEQVR4nO3dPU7jUBiF4ZeMlDRQDz8LQhSsgZ5BtGQJsA5gK/wUmZlmlsAsACJBaKCwkRAYDMrxd78455Fue33vKycE2XHAzMzMzMxsiYyAQ+AKmAJPCzKm9ZoPgKG8itgW8Jfy0eYdf+q9pDSiH5Ffxm+SntmHlI+jHr+khUSuKR9GPS5VcVZUEwF3wKpwvgymwJpiImXoJ+FcmUgaDRSTWDuHDuLQQRw6iEMHceggDh3EoYM4dBCHDuLQQRw6iEMHceggGUM/AmNgox5jYFZ0RcmormocNcy9AzwIj/GdkY5qY+sfzL8N3AuP49CfHKNE7HRUGxu3HCf6bSQd1cYeqGJ+JjJ2OsrNzYDdluNFvY2ko95gltjpdLHJDLHT6WqjpWOn0+XLt2TsdLoMXTK2zBA4Af5nWdCc1B/9ZI6zLUhgTMLQ857JGUP/JFnogXKyRH6UXsBbA+C09CI6sFd6AU2GVO/TNyR4iQmk/WOootrYR2PhP96p9DHyUoX2v+Bv9DHyUoTOELn3obNE7nVoX8pqodpY030dr/nirGj4doMWXYf2DTQ11caa7uvwLWGvqDY24/1NjqUiy0L7S/ft/KX7ReLQQRw6iEMHceggDh3EoYM4dBCHDuLQQRw6iEMHceggDh1EGXoqnCuLW9VEytD/hHNlIduTMvS5cK4szkovoMmI6tn4pS45qceEpI+eh+oHCPoQewJsitvIDal+YuOC6inppaN9ddzVa94n8ZlsZmZmZmYm9wySm1KUuMCfpQAAAABJRU5ErkJggg=="/>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";

export default {
  name: 'LandingTop',
  computed: {
    is_auth() {
      return this.$store.getters['auth/isAuthenticated'];
    },
  },
  setup() {
    let screen_width = inject('screen_width');
    return {screen_width};
  },
  methods: {
    redirectMain() {
      if (this.$route.path === '/' || this.$route.path === '/login' || this.$route.path === '/register' || this.$route.path === '/tariffs' || this.$route.path === '/features' || this.$route.path === '/tutorial') {
        this.$router.push('/');
      } else {
        this.$router.push('/home');
      }
    },
    logout() {
      this.$store.dispatch('auth/logout');
      this.is_auth = false;
    },
  },
};
</script>

<style scoped>
.ytm-landing-head-top-text {
  font-weight: 400;
  font-size: max(1.2vw, 0.8rem);
  color: #191847;
}

.ytm-landing-head-top-button-login {
  background: #07A8F0;
  border: none;
  border-radius: 15px;
  padding: 0.6vw 1.2vw;
  cursor: pointer;
}

.ytm-landing-head-top-button-login-text {
  font-weight: 500;
  font-size: max(1.2vw, 0.8rem);
  color: #FFFFFF;
}

.ytm-mobile-star {
  position: absolute;
  left: -5vw;
  top: -3vh;
  width: 150px;
  z-index: -15;
}

.ytm-mobile-star-logo {
  position: absolute;
  width: 50px;
  height: 38px;
  z-index: 15;
  cursor: pointer;
}

@media only screen and (max-width: 350px) {
  .ytm-mobile-star { width: 130px; }
}

@media only screen and (max-width: 270px) {
  .ytm-mobile-star { width: 120px; }

  .ytm-mobile-star-logo {
    width: 40px;
    top: -10px;
  }
}

@media only screen and (max-width: 200px) {
  .ytm-mobile-star { width: 90px; }
}

.ytm-burger-menu-button {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  z-index: 10;
}
</style>